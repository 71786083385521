<template>
  <validation-observer ref="observer" rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="isOpen"
      @close="isOpen = false"
      submit="Submit"
      :loading="isLoading"
      :disabled="isLoading"
      @submit="handleSubmit(onSubmit)"
      width="520px !important"
      button-class="bg-dynamicBackBtn text-white"
      :disabled-button="false"
    >
      <template v-slot:title>
        <div class="flex justify-start items-center">
          <back-button @onClick="isOpen = false" variant="secondary" />
          <span class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
            New Delagation
          </span>
        </div>
      </template>
      <template>
        <div class="flex flex-col gap-0 w-full">
          <Details :employee-info="delegator" title="DELEGATOR" />
          <Details :employee-info="delegatee" title="DELEGATEE" />
        </div>
      </template>
      <template>
        <div class="w-full flex flex-col justify-start items-start gap-4">
          <div class="w-full flex flex-row justify-between">
            <p class="font-semibold text-base text-darkPurple leading-5">
              Reason for Delegation
            </p>
            <div class="flex justify-start items-center gap-2">
              <Icon icon-name="icon-checked" size="xs" />
              <p class="text-blueYonder text-base leading-5 capitalize">
                <span v-if="delegationReason === 'vacant'">Vacant Position</span>
                <span v-if="delegationReason === 'unavailable'">Unavailable Employee</span>
                <span v-if="delegationReason === 'support'">Support</span>
              </p>
            </div>
          </div>
          <div class="w-full">
            <c-select
              placeholder="--Select--"
              label="Support Type"
              variant="w-full"
              :rules="['required']"
              v-model="supportType"
              :options="supportTypeOptions"
            />
          </div>
          <validation-provider class="w-full" name="period" :rules="{ required: true, period: true }" v-slot="{ errors }">
            <div class="leading-5 text-jet flex flex-col justify-start gap-2 w-full">
              <p class="font-normal text-sm text-jet">Delegation Period</p>
              <div class="border rounded-md pl-2 w-full h-full"
                :class="errors[0] ? 'border-desire' : 'border-romanSilver'">
                <div class="flex justify-start items-center gap-2 w-full h-10">
                  <div class="flex justify-center border-r border-romanSilver h-6 pr-2">
                    <Icon icon-name="calendar" size="xs" />
                  </div>
                  <div class="h-10 mt-1 w-full">
                    <date-range-picker
                      v-model="delegationPeriod"
                      placeholder="Select Period"
                      name="period"
                      range
                    />
                  </div>
                </div>
              </div>
              <small class="text-desire">{{ errors[0] }}</small>
            </div>
          </validation-provider>
          <div class="w-full flex flex-col gap-5">
            <template>
              <div class="flex flex-row justify-between w-full">
                <p class="font-normal text-sm text-jet">
                  Delegated Tasks <span class="text-xs text-flame">(optional)</span>
                </p>
                <div class="w-32 flex flex-row justify-between items-center">
                  <div @click="delegatedTasks.push({ specify: '' })">
                    <div class="flex flex-row justify-start items-center gap-2 cursor-pointer">
                      <p class="text-sm text-flame">Add Task</p>
                      <Icon icon-name="icon-plus" class="text-flame" size="xs" />
                    </div>
                  </div>
                  <div class="cursor-pointer" @click="toggleTasks = !toggleTasks">
                    <Icon
                      :icon-name="toggleTasks ? 'chevronDown' : 'chevronRight'"
                      class="text-flame cursor-pointer" size="xs"
                    />
                  </div>
                </div>
              </div>
            </template>
            <div v-show="toggleTasks">
              <div class="w-full border border-dashed border-romanSilver px-3 py-3 rounded-md">
                <div class="flex flex-col justify-start gap-5">
                  <div v-for="(task, i) in delegatedTasks" :key="i" class="relative">
                    <div class="flex flex-row justify-between items-center gap-2 relative">
                      <c-text
                        :label="`Task ${i+1}`"
                        placeholder="Specify"
                        class="w-full"
                        v-model="task.specify"
                        :rules="[].concat(delegatedTasks.length > 1 ? ['required']: [])"
                      />
                      <div
                        v-if="delegatedTasks.length > 1"
                        class="mt-5 cursor-pointer"
                        @click="delegatedTasks.splice(i, 1)"
                      >
                        <Icon icon-name="delete" class="text-flame" size="xs" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </RightSideBar>
  </validation-observer>
</template>

<script>
  import * as _ from 'lodash'
  import DateRangePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css'
  import { ValidationObserver, ValidationProvider, extend } from "vee-validate"
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button"
  import CSelect from "@scelloo/cloudenly-ui/src/components/select"
  import CText from "@scelloo/cloudenly-ui/src/components/text"
  import RightSideBar from "@/components/RightSideBar"
  import Icon from "@/components/Icon"

  extend('period', {
    message: 'Delegation Period is not valid',
    validate: (period) => (
      _.isDate(_.first(period)) && _.isDate(_.last(period))
    )
  })

  export default {
    emits: [],
    components: {
      ValidationObserver,
      ValidationProvider,
      DateRangePicker,
      BackButton,
      CSelect,
      CText,
      Icon,
      RightSideBar,
      Details: () => import('./details'),
    },
    props: {
      delegator: {
        type: Object,
        default: () => {}
      },
      delegatee: {
        type: Object,
        default: () => {}
      },
      delegation: {
        type: Object,
        default: () => {}
      }
    },
    data(){
      return {
        isOpen: false,
        isLoading: false,
        toggleTasks: true,
        delegationId: null,
        supportType: '',
        initiatedBy: '',
        delegationPeriod: [],
        delegatedTasks: [],
        supportTypeOptions: [
          { name: 'Time Management', id: 'time' },
          { name: 'Skill Management', id: 'skill' },
          { name: 'Engagement & Collaboration', id: 'engagement' },
          { name: 'Resource Efficiency', id: 'resource' },
          { name: 'Growth & Development', id: 'growth' },
        ],
      }
    },
    methods: {
      toggle(delegation){
        this.isLoading = true

        if(_.size(_.keys(delegation))){
          this.delegationId = delegation.id
          this.initiatedBy = delegation.initiatedBy
          this.supportType = delegation.supportType
          this.delegationReason = delegation.delegationReason
          this.delegatedTasks = delegation.delegatedTask || [{specify: ''}]
          this.delegationPeriod[0] = delegation.delegationPeriodStart
          this.delegationPeriod[1] = delegation.delegationPeriodEnd
        }

        this.isOpen = !this.isOpen
        this.isLoading = false
      },

      convertToISO(data) {
        const originalDate = new Date(data);

        const utcDate = new Date(originalDate.getTime() - (originalDate.getTimezoneOffset() * 60000));

        const isoDate = utcDate.toISOString();

        return isoDate;
      },

      onSubmit(){
        this.isLoading = true
        const payload = {
          orgId: this.$orgId,
          initiatedBy: this.initiatedBy || this.$AuthUser.id,
          delegator: this.delegator.userId,
          delegatee: this.delegatee.userId,
          supportType: this.supportType,
          delegatedTask: _.filter(this.delegatedTasks, i => i.specify),
          delegationReason: this.delegationReason,
          delegationPeriodStart: this.convertToISO(_.first(this.delegationPeriod)),
          delegationPeriodEnd: this.convertToISO(_.last(this.delegationPeriod)),
        }

       if(this.delegationId) payload.id = this.delegationId
        const query = payload.id ? '&update=true' : null

        this.$_employeeActionDelegateEmployee({ payload, query }).then(({ data }) => {
          const message = "Delegation sent for approval successfully!"
          this.$toasted.success(message, { duration: 3000 })
          this.$emit('success', data)
          this.isLoading = false
          this.isOpen = false
        }).catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 })
          this.isLoading = false
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
  .payInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  &_fakeInput {
    background: #f7f7ff;
    border-color: #878e99;
    padding: 10px 15px;
    height: 40px;
  }
}
</style>
<style lang="scss">
.daterange-picker {
  position: absolute !important;
  z-index: 10000;
  .calendar{
    display: none;
  }
  .inputs-container {
    border: 0 !important;
  }
  .divider {
    padding: 0 !important;
  }
  input {
    font-size: 13px !important;
    width: 90px;
  }
}
.mx-datepicker, .mx-datepicker-range {
  width: 100% !important;
}
.mx-input {
  box-shadow: none !important;
  border: none !important;
  width: 95% !important;
}
.mx-icon-calendar {
  display: none !important;
}
.timeline-class {
  display: none!important;
}
._error-color {
  border-color: rgba(238, 43, 9, 0.952);
  color: rgba(238, 43, 9, 0.952);
}
</style>

